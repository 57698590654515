/** List of all route paths. */
export const routePaths = {
	home: ['/'],

	// Auth Module.
	login: ['/auth/login'],
	forgotPassword: ['/auth/forgot-password'],
	resetPassword: ['/auth/reset-password'],

	// Customers Module.
	dashboard: ['/dashboard'],

	// Customers Module.
	customers: ['/customers'],
	customerDetail: (id: string | number) => ([...routePaths.customers, id, 'detail']),
	buildingInformation: (id: string | number) => ([...routePaths.customers, id, 'building-info']),
	customerDevices: (id: string | number) => ([...routePaths.customers, id, 'devices']),
	customerProposals: (id: string | number) => ([...routePaths.customers, id, 'proposals']),
	customerInvoices: (id: string | number) => ([...routePaths.customers, id, 'invoices']),
	customerInspections: (id: string | number) => ([...routePaths.customers, id, 'inspections']),
	customerPeriodic: (id: string | number) => ([...routePaths.customers, id, 'periodic']),
	customerDOB: (id: string | number) => ([...routePaths.customers, id, 'dob-now-status']),
	customerNotes: (id: string | number) => ([...routePaths.customers, id, 'notes']),

	// Devices Module.
	devices: ['/devices'],

	// Proposals Module.
	proposals: ['/proposals'],

	// Invoices Module.
	invoices: ['/invoices'],

	// Inspections Module.
	inspections: ['/inspections'],

	// Periodic Module.
	periodic: ['/periodic'],

	// Schedules Module.
	schedules: ['/schedules'],

	// Buildings Module.
	buildings: ['/buildings'],

	// Dob Now Module.
	dobNow: ['/dob-now'],

	// Next Years Module.
	nextYears: ['/next-years'],

	// Contacts Module.
	contacts: ['/contacts'],
	maintenanceCompany: ['/contacts/maintenance-company'],
	managementCompany: ['/contacts/management-company'],
	inspectorContacts: ['/contacts/inspector'],
	customerContacts: ['/contacts/customers'],
};
